"use client";

import axios from 'axios';
import { User } from 'common/types/user';
import { useRouter } from 'next/router';
import React, { createContext, ReactNode, FC, useState, useMemo } from 'react';
import Cookies from 'universal-cookie';

type Props = {
  children: ReactNode;
  flowUser: string;
};
export interface UserTypes {
  user: User;
  setUser: any;
  handleChangeImage: any;
}
export const Context = createContext({} as any);

export const cookies = new Cookies();
type UserType = any;
export const Provider: FC<Props> = ({ children, flowUser }) => {
  const [user, setUser] = useState<UserType>(() => {
    try {
      // Check if flowUser is a string and try to parse it
      if (typeof flowUser === 'string') {
        return JSON.parse(flowUser) as UserType;
      }
      // If flowUser is already an object, return it directly
      else if (typeof flowUser === 'object' && flowUser !== null) {
        return flowUser as UserType;
      }
      // If flowUser is neither a string nor an object, return a default value
      else {
        console.warn('Unexpected type for flowUser:', typeof flowUser);
        return {};
      }
    } catch (error) {
      console.error('Error handling user data:', error);
      return {}; // Set user to a default value or an empty object, based on your use case
    }
  });


  const handleChangeImage = (image: string) => {
    setUser((prevState: any) => {
      return {
        ...prevState,
        image,
      };
    });

    cookies.set(
      'flowUser',
      {
        name: user.name,
        email: user.email,
        image: image,
        features: user.features,
        uid: user.uid,
      },
      {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
        sameSite: true,
      },
    );
  };


  const configContext = {
    user,
    setUser,
    handleChangeImage,
  };

  return <Context.Provider value={configContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
